import { useLocationData } from 'lib/search/hooks/query/use-location-data';
import { useSearchParams } from 'lib/search/hooks/query/use-search';

export const useShowRadiusFilter = (): boolean => {
  const searchParams = useSearchParams();

  const { locationData } = useLocationData({
    slug: searchParams?.slug,
    area: searchParams?.area,
    market: searchParams?.market,
  });

  const isSearchOnMove = !!searchParams?.searchOnMove;

  const searchType = locationData?.type || '';
  return (
    !!searchType &&
    ['town', 'city'].includes(searchType) &&
    // hide radius filter if map has been moved
    !isSearchOnMove
  );
};
