import { IAddress } from '@bridebook/models/source/models/Weddings.types';
import { CountryCodes } from '@bridebook/toolbox/src/gazetteer';

export const getAreaContext = (
  location: Pick<IAddress, 'country'> | null,
  countryCode: CountryCodes,
): string[] => {
  if (countryCode === CountryCodes.GB) {
    return [];
  }

  if (!location?.country) {
    return [countryCode];
  }

  return countryCode !== location.country ? [location.country, countryCode] : [location.country];
};
