import { omit } from 'ramda';
import { ofType } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { ISupplier } from '@bridebook/models/source/models/Suppliers.types';
import { extractSupplier, getSupplierUrl } from '@bridebook/toolbox/src';
import { authenticatedPOST } from '@bridebook/toolbox/src/api/auth/authenticated-fetch';
import gazetteer, { CountryCodes, Gazetteer } from '@bridebook/toolbox/src/gazetteer';
import { IElasticSupplier, IUISupplier } from '@bridebook/toolbox/src/types';
import { ViewedSupplierProfileEventBody } from 'pages/api/a/t/viewed-supplier-profile';
import { getSupplierL10nAnalytics } from 'lib/analytics-utils/get-supplier-l10n';
import { WebAnalyticsContext } from 'lib/bbcommon/utils/bridebook-analytics';
import { EnquiriesActionTypes } from 'lib/enquiries/action-types';
import { enquiryPropertiesGeneric } from 'lib/enquiries/analytics';
import getEnquiriesAnalyticsProps from 'lib/enquiries/utils/get-analytics-props';
import { env } from 'lib/env';
import { SearchActionTypes } from 'lib/search/action-types';
import { getSearchList } from 'lib/search/selectors';
import { isBudgetMatch } from 'lib/supplier/utils/budget-match';
import { Action, IApplicationState, ISupplierState, ReviewFormFields } from 'lib/types';
import { getVideoIframeUrl } from 'lib/utils';
import { assertState } from 'lib/utils/assertState';
import getServerSideAnalyticsProps from 'lib/utils/get-server-side-analytics-props';
import collaboratingSupplierTrack from 'lib/utils/server-side-track/collaborating-supplier-track';
import { getWeddingId } from 'lib/weddings/selectors';
import { IProfile } from 'lib/weddings/types';
import reviewTrack from '../utils/server-side-track/review-track';
import {
  IClickedToGoToMessageAnalytics,
  supplierAndCoupleCollaboratingAnalytics,
  usedSearchCarouselAnalytics,
  viewedEndScreenOnSupplierCarouselAnalytics,
  viewedSupplierProfileSectionAnalytics,
} from './actions';
import {
  ISupplierBrochureAnalyticsAction,
  ISupplierCardInViewAction,
  IViewedSupplierAnalyticsAction,
  SupplierActions,
} from './actions-types';
import { getCurrentSupplier, getPremium } from './selectors';
import { SupplierCategory } from './types';

const getReviewLocation = (pathname: string): string =>
  pathname === '/reviews' ? 'reviewPage' : 'supplierProfile';

interface IViewedSupplierGenericProperties {
  viewedSupplierCategory: string;
  viewedSupplierId: string;
  viewedSupplierName: string;
  viewedSupplierProfileURL: string;
  supplierTier: number;
  budgetMatch?: boolean;
}

export const viewedSupplierPropertiesGeneric = ({
  supplier,
  profile,
  getState,
}: {
  supplier: IUISupplier | ISupplier | null | undefined;
  profile?: IProfile;
  getState: () => IApplicationState;
}): IViewedSupplierGenericProperties => {
  const { type, publicId, id, name, town, county, seo } =
    typeof (supplier as IUISupplier)?.type === 'string'
      ? (supplier as IUISupplier)
      : extractSupplier(supplier as ISupplier);

  const premium = getPremium(getState());
  const searchList = getSearchList(getState());

  const searchPosition = searchList.findIndex((s) => s.id === id);

  const props: IViewedSupplierGenericProperties = {
    viewedSupplierCategory: type,
    viewedSupplierId: id,
    viewedSupplierName: name,
    viewedSupplierProfileURL:
      env.COUPLESIDE_URL +
      getSupplierUrl({
        type,
        publicId,
        id,
        name,
        town,
        county,
        seoUrlSlug: seo?.urlSlug,
      }),
    //Where is supplier premium in CMS?
    supplierTier: (supplier as IUISupplier)?.tier || premium?.tier || 0,
    ...(searchPosition > -1 && {
      searchPosition: searchPosition + 1,
    }),
  };

  if (type === 'venue' && profile && profile.id) {
    props.budgetMatch = isBudgetMatch(profile.budget, supplier);
  }

  return props;
};

interface ReviewGenericProperties {
  reviewRating: number;
  reviewTitle: string;
  reviewMessage: string;
  reviewPhotoUrl: string;
  reviewBy: string;
  reviewWeddingDate: string;
  reviewName: string;
  reviewEmail: string;
  reviewCaptcha: boolean;
  reviewLocation: string;
}

interface ReviewPropertiesGeneric {
  fields: ReviewFormFields;
  recaptchaComplete: boolean;
  reviewLocation: string;
}

export const reviewPropertiesGeneric = ({
  fields,
  recaptchaComplete,
  reviewLocation,
}: ReviewPropertiesGeneric): ReviewGenericProperties => ({
  reviewRating: fields.stars,
  reviewTitle: fields.title,
  reviewMessage: fields.message,
  reviewPhotoUrl: fields.photo.public_id
    ? `https://res.cloudinary.com/bridebook/image/upload/${fields.photo.public_id}`
    : '',
  reviewBy: fields.from,
  reviewWeddingDate: fields.weddingDate,
  reviewName: fields.name,
  reviewEmail: fields.email,
  reviewCaptcha: recaptchaComplete,
  reviewLocation: reviewLocation || '',
});

interface SocialShareProperties {
  socialShareType: string;
  socialShareMethod: string;
}

export function getSocialSharePropertiesGeneric(network: string): SocialShareProperties {
  return {
    socialShareType: 'supplierProfile',
    socialShareMethod: network,
  };
}

export const clickedSupplierPropertiesGeneric = (
  supplier: ISupplier | IUISupplier | IElasticSupplier,
  getState: () => IApplicationState,
) => {
  const premium = getPremium(getState());

  return {
    supplierId: supplier.id,
    supplierCategory: supplier.type,
    supplierName: supplier.name,
    supplierTier: (supplier as IUISupplier).tier || premium?.tier || 0,
    supplierCountryCode:
      (supplier as IElasticSupplier).countryCode || (supplier as ISupplier).address?.country,
  };
};

const filterLabelProps = (props: Record<string, any>) =>
  omit(['category', 'reviewMessage', 'reviewPhotoUrl', 'reviewEmail', 'contactMessage'], props);

interface InteractedSupplierGenericProperties {
  interactedSupplierId: string;
  interactedSupplierCategory: string;
  interactedSupplierName: string;
  supplierTier?: number;
}

export const interactedSupplierPropertiesGeneric = (
  supplier?: ISupplier | IUISupplier | null,
  premium?: ISupplierState['premium'],
): InteractedSupplierGenericProperties => {
  supplier = supplier || ({} as ISupplier | IUISupplier);

  return {
    interactedSupplierId: supplier.id || '',
    interactedSupplierCategory: Array.isArray(supplier.type) ? supplier.type?.[0] : supplier.type,
    interactedSupplierName: supplier.name || '',
    supplierTier: premium?.tier || (supplier as IUISupplier).tier || 0,
  };
};

interface ClickedContactProperties {
  clickedContactLinkType: string;
  clickedContactLinkLocation: string;
  clickedContactSupplierCategory: string;
  clickedContactSupplierId: string;
  clickedContactSupplierName: string;
  supplierTier: number;
}

export const clickedContactPropertiesGeneric = ({
  supplier,
  type,
  location,
  getState,
}: {
  supplier: IUISupplier | ISupplier;
  type: string;
  location: string;
  getState: () => IApplicationState;
}): ClickedContactProperties => {
  const {
    id,
    type: clickedContactSupplierCategory,
    name,
  } = Array.isArray(supplier.type)
    ? extractSupplier(supplier as ISupplier)
    : (supplier as IUISupplier);
  const premium = getPremium(getState());

  return {
    clickedContactLinkType: type,
    clickedContactLinkLocation: location,
    clickedContactSupplierCategory,
    clickedContactSupplierId: id,
    clickedContactSupplierName: name,
    supplierTier: (supplier as IUISupplier).tier || premium?.tier || 0,
  };
};

interface ISupplierGenericProperties {
  countryCode: CountryCodes;
  countryName?: string;
  locale: string;
  supplierCategory: string;
  supplierId: string;
  supplierName: string;
  supplierTier: number;
}

export const supplierPropertiesGeneric = ({
  supplier,
  getState,
}: {
  supplier: IUISupplier | ISupplier;
  getState: () => IApplicationState;
}): ISupplierGenericProperties => {
  const premium = getPremium(getState());
  const countryCode =
    (supplier as IElasticSupplier).countryCode || (supplier as ISupplier).address?.country;
  const countryName = countryCode ? Gazetteer.getCountryName(countryCode) : undefined;
  const locale = gazetteer.getMarketByCountry(countryCode, CountryCodes.GB).locale;

  const { id: supplierId, type, name: supplierName } = supplier;

  return {
    supplierId,
    supplierCategory: type?.[0],
    supplierName,
    supplierTier: (supplier as IUISupplier).tier || premium?.tier || 0,
    countryCode,
    countryName,
    locale,
  };
};

export const supplierAnalyticsEpic = (action$: Observable<Action>) =>
  action$.pipe(
    ofType<Action, Action, SupplierActions | EnquiriesActionTypes>(
      SupplierActions.TOGGLE_REVIEW_FORM,
      EnquiriesActionTypes.ON_SUPPLIER_ENQUIRY_INTERACT_ANALYTICS,
    ),
    mergeMap(({ type, payload }) => {
      switch (type) {
        case SupplierActions.TOGGLE_REVIEW_FORM: {
          const { show, method } = payload;
          return method
            ? of({
                type: show
                  ? SupplierActions.TRIGGERED_REVIEW_MODAL_ANALYTICS
                  : SupplierActions.CLOSED_REVIEW_MODAL_ANALYTICS,
                payload: { method },
              })
            : of();
        }
        case EnquiriesActionTypes.ON_SUPPLIER_ENQUIRY_INTERACT_ANALYTICS: {
          const {
            type: eventType,
            method = '',
            actionLabel = '',
            locationType = '',
            contactGalleryCTA,
            contactSection,
          } = payload;

          return of({
            type: SupplierActions.CLICKED_TO_TRIGGER_ENQUIRY_FORM_ANALYTICS,
            payload: {
              eventType,
              method,
              actionLabel,
              locationType,
              contactGalleryCTA,
              contactSection,
            },
          });
        }
        default:
          return of();
      }
    }),
  );

const getSupplierFromImageUrl = (imageUrl: string, list: IUISupplier[]) => {
  const [, supplierId] = imageUrl.replace(/.*weddingsuppliers\//, '').split('/');
  return list.find((s) => s.id === supplierId) || list.find((s) => s.publicId === supplierId);
};

type TSupplierAnalyticsAction = Action | IViewedSupplierAnalyticsAction;

export default function supplierAnalytics(
  { type, payload }: TSupplierAnalyticsAction,
  bridebookAnalytics: WebAnalyticsContext,
  getState: () => IApplicationState,
) {
  const { track } = bridebookAnalytics.getMethods('Directory', filterLabelProps);

  switch (type) {
    case SupplierActions.CLICKED_NEXT_SUPPLIER_PROFILE_ANALYTICS: {
      const { nextSupplier } = payload;
      track({
        event: 'Clicked next supplier profile',
        ...viewedSupplierPropertiesGeneric({ supplier: nextSupplier, getState }),
      });
      break;
    }
    case SupplierActions.VIEWED_SUPPLIER_PROFILE_ANALYTICS: {
      const { supplier, refSupplier } = payload as IViewedSupplierAnalyticsAction['payload'];
      const state = getState();
      const {
        app: { lastPath },
        shortlist: { shortlistedLocation },
        weddings: { profile },
      } = state;
      const supplierL10n = getSupplierL10nAnalytics(supplier);

      const body = {
        genericProps: omit(['userId', 'weddingId'], getServerSideAnalyticsProps(state)),
        ...viewedSupplierPropertiesGeneric({
          supplier,
          profile,
          getState,
        }),
        ...(refSupplier?.viewedLocation
          ? {
              linkedSupplierId: refSupplier?.supplierId,
              linkedSupplierName: refSupplier?.supplierName,
              viewedLocation: refSupplier?.viewedLocation,
            }
          : {
              viewedLocation: shortlistedLocation,
            }),
        previousPath: lastPath,
        ...supplierL10n,
      };

      authenticatedPOST<ViewedSupplierProfileEventBody>(`/api/a/t/viewed-supplier-profile`, {
        body,
      });
      break;
    }
    case SupplierActions.WATCHED_SUPPLIER_VIDEO_ANALYTICS: {
      const supplier = getCurrentSupplier(getState());
      const {
        video: { id },
        video,
        viewLocation,
        mediaType,
      } = payload;

      track({
        event: 'Watched supplier video',
        ...viewedSupplierPropertiesGeneric({ supplier, getState }),
        watchedSupplierVideoId: id,
        watchedSupplierVideoUrl: getVideoIframeUrl(video),
        viewLocation,
        mediaType,
      });
      break;
    }
    case SupplierActions.CLICKED_SUPPLIER_SOCIAL_MEDIA_ANALYTICS: {
      const supplier = getState().supplier.supplier.data;
      const { method } = payload;
      track({
        event: 'Clicked supplier social media',
        ...viewedSupplierPropertiesGeneric({ supplier, getState }),
        clickedSupplierSocialMediaMethod: method,
      });
      break;
    }
    case SupplierActions.REVEALED_MORE_CONTENT_ON_SUPPLIER_PROFILE_ANALYTICS: {
      const supplier = getState().supplier.supplier.data;
      const { method, section } = payload;

      track({
        event: 'Revealed more content on supplier profile',
        ...viewedSupplierPropertiesGeneric({ supplier, getState }),
        revealedMoreContentMethod: method,
        revealedMoreContentSection: section,
      });
      break;
    }
    case SupplierActions.CLICKED_SUPPLIER_CONTACT_LINK_ANALYTICS: {
      const { supplier, type, location } = payload;
      track({
        event: 'Clicked supplier contact link',
        category: 'Supplier Engagement',
        ...clickedContactPropertiesGeneric({ supplier, type, location, getState }),
      });
      break;
    }
    case SupplierActions.USED_QUICK_NAVIGATION_ON_SUPPLIER_PROFILE_ANALYTICS: {
      const supplier = getCurrentSupplier(getState());
      const { method, section } = payload;

      track({
        event: 'Used quick navigation on supplier profile',
        ...viewedSupplierPropertiesGeneric({ supplier, getState }),
        usedQuickNavigationMethod: method,
        usedQuickNavigationSection: section,
      });
      break;
    }
    case SupplierActions.SCROLLED_SUPPLIER_MEDIA_GALLERY_ANALYTICS: {
      const supplier = getCurrentSupplier(getState());
      track({
        event: 'Scrolled supplier media gallery',
        ...viewedSupplierPropertiesGeneric({ supplier, getState }),
      });
      break;
    }
    case SupplierActions.TOGGLED_SUPPLIER_MEDIA_GALLERY_ANALYTICS: {
      const supplier = getCurrentSupplier(getState());
      const { isGalleryOpened, toggledFrom } = payload;
      track({
        event: 'Toggled supplier media gallery',
        ...viewedSupplierPropertiesGeneric({ supplier, getState }),
        toggledSupplierMediaGalleryStatus: isGalleryOpened,
        triggeredSupplierMediaGalleryLocation: toggledFrom,
      });
      break;
    }
    case SupplierActions.VIEWED_MOOD_BOARD_ANALYTICS: {
      const supplier = getCurrentSupplier(getState());
      const { mediaType } = payload;
      track({
        event: 'Viewed mood board',
        ...viewedSupplierPropertiesGeneric({ supplier, getState }),
        mediaType,
      });
      break;
    }
    case SupplierActions.VIEWED_END_SCREEN_ON_SUPPLIER_CAROUSEL_ANALYTICS: {
      const { imagesCount, location, firstImageUrl } = payload as ReturnType<
        typeof viewedEndScreenOnSupplierCarouselAnalytics
      >['payload'];
      const { list } = getState().search;
      const supplier = getSupplierFromImageUrl(firstImageUrl, list);

      track({
        event: 'Viewed end screen on supplier photo carousel',
        ...viewedSupplierPropertiesGeneric({ supplier, getState }),
        supplierPhotoCarouselNumberOfPhotos: imagesCount,
        supplierPhotoCarouselLocation: location,
      });
      break;
    }

    case SearchActionTypes.USED_SEARCH_CAROUSEL_ANALYTICS: {
      const { numberOfPhotos, supplier, location } = payload as ReturnType<
        typeof usedSearchCarouselAnalytics
      >['payload'];

      track({
        event: 'Used search carousel',
        ...viewedSupplierPropertiesGeneric({ supplier, getState }),
        supplierPhotoCarouselNumberOfPhotos: numberOfPhotos,
        supplierPhotoCarouselLocation: location,
      });
      break;
    }

    case SupplierActions.TRIGGERED_SUPPLIER_SOCIAL_SHARE_ANALYTICS: {
      const supplier = getCurrentSupplier(getState());
      const { network } = payload;

      track({
        event: 'Triggered social share',
        category: 'Social sharing',
        ...viewedSupplierPropertiesGeneric({ supplier, getState }),
        ...getSocialSharePropertiesGeneric(network),
      });
      break;
    }
    case SupplierActions.CLICKED_UP_NEXT_VENUE_ANALYTICS: {
      const { supplier } = payload;
      const { premium } = getState().supplier;
      track({
        event: 'Clicked up next venue',
        ...interactedSupplierPropertiesGeneric(supplier, premium),
      });
      break;
    }
    case SupplierActions.SUBMITTED_REVIEW_ANALYTICS: {
      const state = getState();
      const {
        supplier: {
          reviewForm: { recaptchaComplete, fields },
        },
        app: { pathname },
      } = state;

      const supplier = getCurrentSupplier(state);
      const supplierCountry = supplier.l10n.country;
      const supplierCountryName = supplierCountry
        ? Gazetteer.getCountryName(supplierCountry)
        : undefined;

      if (!supplier?.id) return;

      const reviewLocation = getReviewLocation(pathname);

      reviewTrack({
        state,
        event: 'Submitted review',
        category: 'Reviews',
        viewedSupplierId: supplier.id,
        specificEventProps: {
          supplierCountry,
          supplierCountryName,
          ...viewedSupplierPropertiesGeneric({ supplier, getState }),
          ...reviewPropertiesGeneric({
            fields,
            recaptchaComplete,
            reviewLocation,
          }),
        },
      });

      break;
    }
    case SupplierActions.TRIGGERED_REVIEW_MODAL_ANALYTICS:
    case SupplierActions.CLOSED_REVIEW_MODAL_ANALYTICS: {
      const state = getState();
      const {
        app: { pathname },
      } = state;
      const supplier = getCurrentSupplier(state);
      const { method } = payload;
      const triggered = type === SupplierActions.TRIGGERED_REVIEW_MODAL_ANALYTICS;
      const event = triggered ? 'Triggered review modal' : 'Closed review modal';

      track({
        event,
        category: 'Reviews',
        ...viewedSupplierPropertiesGeneric({ supplier, getState }),
        [`${triggered ? 'triggeredModalMethod' : 'closedModalMethod'}`]: method,
        reviewLocation: getReviewLocation(pathname),
      });
      break;
    }
    case SupplierActions.FAILED_TO_SUBMIT_REVIEW_ANALYTICS: {
      const state = getState();
      const {
        supplier: {
          reviewForm: { recaptchaComplete, fields },
        },
        app: { pathname },
      } = state;
      const supplier = getCurrentSupplier(state);
      const { message } = payload;

      const reviewLocation = getReviewLocation(pathname);

      track({
        event: 'Failed to submit review',
        category: 'Reviews',
        ...viewedSupplierPropertiesGeneric({ supplier, getState }),
        ...reviewPropertiesGeneric({
          fields,
          recaptchaComplete,
          reviewLocation,
        }),
        failedToSubmitReviewReason: message,
      });
      break;
    }
    // Enquiry tracking events v3 [client side]
    // https://docs.google.com/spreadsheets/d/1PCVolpzilfk-1t-u8Acmpd9AFakbdclA21cu53VD-ic/edit#gid=1074403805
    case SupplierActions.CLICKED_TO_TRIGGER_ENQUIRY_FORM_ANALYTICS: {
      const {
        eventType,
        method,
        actionLabel,
        locationType,
        contactGalleryCTA,
        contactSection: payloadContactSection,
      } = payload;
      const {
        supplier: {
          supplier: { data: supplier },
          premium,
        },
        enquiries: { enquirySupplier },
      } = getState();
      let event;
      let additionalProps = {};

      switch (eventType) {
        case 'triggered':
          event = 'Clicked to trigger enquiry form';
          additionalProps = enquirySupplier
            ? getSupplierL10nAnalytics(enquirySupplier)
            : supplier
            ? getSupplierL10nAnalytics(supplier)
            : {};
          break;
        case 'edit':
          event = 'Clicked to edit enquiry details';
          break;
        default:
          event = 'Clicked to send enquiry';
      }

      const { contactLocation, contactLocationType, contactActionLabel, contactSection } =
        getEnquiriesAnalyticsProps({
          getState,
          infoProps: {
            method,
            actionLabel,
            locationType,
            contactSection: payloadContactSection,
          },
        });

      track({
        event,
        category: 'Supplier Engagement',
        ...interactedSupplierPropertiesGeneric(enquirySupplier || supplier, premium),
        ...enquiryPropertiesGeneric({
          getState,
          supplier: enquirySupplier || supplier,
          contactLocation,
          contactActionLabel,
          contactLocationType,
          contactGalleryCTA,
          contactSection,
        }),
        ...additionalProps,
      });
      break;
    }
    case SupplierActions.DOWNLOADED_SUPPLIER_BROCHURE_ANALYTICS: {
      const premium = getState().supplier.premium;
      assertState(getState().supplier.supplier, 'loaded', 'supplier');
      const supplier = getState().supplier.supplier.data;
      const { interactedSupplierBrochureLink } =
        payload as ISupplierBrochureAnalyticsAction['payload'];

      track({
        event: 'Downloaded supplier brochure',
        ...interactedSupplierPropertiesGeneric(supplier, premium),
        interactedSupplierBrochureLink,
      });
      break;
    }
    case SupplierActions.CLICKED_ON_FACETTING_LINK_ANALYTICS:
      {
        const { facet } = payload || {};
        const premium = getState().supplier.premium;
        assertState(getState().supplier.supplier, 'loaded', 'supplier');
        const supplier = getState().supplier.supplier.data;
        track({
          event: 'Clicked on facetting link',
          facet,
          ...interactedSupplierPropertiesGeneric(supplier, premium),
        });
      }
      break;
    case SupplierActions.VIEWED_SUPPLIER_TILE_ANALYTICS: {
      const { supplier, supplierTileOrder, viewedLocation, refSupplier } =
        payload as ISupplierCardInViewAction['payload'];
      track({
        event: 'Viewed supplier tile',
        supplierTileOrder,
        ...(refSupplier?.viewedLocation
          ? {
              linkedSupplierId: refSupplier?.supplierId,
              linkedSupplierName: refSupplier?.supplierName,
              viewedLocation: refSupplier?.viewedLocation,
            }
          : {
              viewedLocation,
            }),
        ...viewedSupplierPropertiesGeneric({ supplier, getState }),
      });
      break;
    }

    case SupplierActions.TOGGLE_WHY_BRIDEBOOK_MODAL_ANALYTICS: {
      track({
        event: payload ? 'Triggered enquiry reasons pop up' : 'Closed enquiry reasons pop up',
      });
      break;
    }

    case SupplierActions.SUPPLIER_AND_COUPLE_COLLABORATING_ANALYTICS: {
      const { collaboratingSupplierId, collaborationTriggerLocation, started } =
        payload as ReturnType<typeof supplierAndCoupleCollaboratingAnalytics>['payload'];

      collaboratingSupplierTrack({
        state: getState(),
        event: started
          ? 'Supplier and couple started collaborating'
          : 'Supplier and couple stopped collaborating',
        specificEventProps: {
          collaborationTriggerLocation,
        },
        category: 'Supplier-couple collaboration',
        collaboratingSupplierId,
      });

      break;
    }

    case SupplierActions.SHARED_SUPPLIER_PROFILE_LINK_ANALYTICS: {
      const { appChosen, url, sharedLocation, supplier } = payload;

      track({
        event: 'Shared supplier profile link',
        category: 'Social sharing',
        appChosen,
        sharedLocation,
        supplierProfileLink: url,
        ...viewedSupplierPropertiesGeneric({ supplier, getState }),
      });
      break;
    }

    case SupplierActions.CLICKED_SUPPLIER_CATEGORY_TAGS_ANALYTICS: {
      const { supplierCategory }: { supplierCategory: SupplierCategory } = payload;

      track({
        event: 'Clicked supplier category tags',
        exploreSupplierCategory: supplierCategory.value,
      });
      break;
    }

    case SupplierActions.CLICKED_SEE_ALL_REVIEWS: {
      const supplier = getState().supplier.supplier.data;

      track({
        event: 'Clicked see all reviews',
        ...viewedSupplierPropertiesGeneric({ supplier, getState }),
      });
      break;
    }

    case SupplierActions.CLICKED_SEE_REVIEW: {
      const supplier = getState().supplier.supplier.data;
      const { selectedReview } = payload;

      track({
        event: 'Clicked see review',
        ...viewedSupplierPropertiesGeneric({ supplier, getState }),
        selectedReview,
      });
      break;
    }

    case SupplierActions.CLICKED_SHOW_MORE_REVIEW: {
      const supplier = getState().supplier.supplier.data;
      const { selectedReview } = payload;

      track({
        event: 'Clicked show more review',
        ...viewedSupplierPropertiesGeneric({ supplier, getState }),
        selectedReview,
      });
      break;
    }

    case SupplierActions.MAP_IN_VIEW: {
      const supplier = getCurrentSupplier(getState());

      track({
        event: 'Viewed profile map',
        viewedLocation: 'venueProfile',
        ...viewedSupplierPropertiesGeneric({ supplier, getState }),
      });
      break;
    }

    case SupplierActions.MAP_MOUSE_UP: {
      const supplier = getCurrentSupplier(getState());

      track({
        event: 'Interacted with profile map',
        interactionType: 'Mouse up',
        viewedLocation: 'venueProfile',
        ...viewedSupplierPropertiesGeneric({ supplier, getState }),
      });
      break;
    }

    case SupplierActions.INSTAGRAM_GALLERY_VIEWED_ANALYTICS: {
      const supplier = getCurrentSupplier(getState());
      track({
        event: 'Viewed supplier social media gallery',
        ...viewedSupplierPropertiesGeneric({ supplier, getState }),
        socialMediaType: 'instagram',
      });
      break;
    }

    case SupplierActions.INSTAGRAM_GALLERY_SCROLLED_ANALYTICS: {
      const supplier = getCurrentSupplier(getState());
      track({
        event: 'Scrolled supplier social media gallery',
        ...viewedSupplierPropertiesGeneric({ supplier, getState }),
        socialMediaType: 'instagram',
      });
      break;
    }

    case SupplierActions.CLICKED_GO_TO_MESSAGE_ANALYTICS: {
      const { supplier: payloadSupplier, ...clickDetails } =
        payload as IClickedToGoToMessageAnalytics;

      const supplier = payloadSupplier ? payloadSupplier : getCurrentSupplier(getState());

      track({
        event: 'Clicked to go to message',
        ...clickedSupplierPropertiesGeneric(supplier, getState),
        ...clickDetails,
      });
      break;
    }

    case SupplierActions.VIEWED_SUPPLIER_PROFILE_SECTION_ANALYTICS: {
      const { viewedMethod, viewedSection } = payload as ReturnType<
        typeof viewedSupplierProfileSectionAnalytics
      >['payload'];
      const supplier = getCurrentSupplier(getState());
      const weddingId = getWeddingId(getState());

      track({
        event: 'Viewed supplier profile section',
        category: 'Directory',
        viewedMethod,
        viewedSection,
        weddingId,
        ...supplierPropertiesGeneric({ supplier, getState }),
      });
      break;
    }

    default:
      break;
  }
}
