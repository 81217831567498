import { Locale, format, setMonth } from 'date-fns';
import { mapObjIndexed, pickBy } from 'ramda';
import { getSeasonInMonths } from '@bridebook/toolbox/src/datepicker/get-season-in-months';
import type { Month, Season, WeekDay } from '@bridebook/toolbox/src/datepicker/types';
import { Market } from '@bridebook/toolbox/src/gazetteer';
import { getI18n } from 'lib/i18n/getI18n';

export type PriceFilterWeekDay = 'monWed' | 'thu' | 'fri' | 'sat' | 'sun';

export const priceFilterSeasonsArray: Season[] = ['spring', 'summer', 'autumn', 'winter'];
export const priceFilterWeekDaysArray: PriceFilterWeekDay[] = [
  'monWed',
  'thu',
  'fri',
  'sat',
  'sun',
];

type SeasonOption = {
  translationSeason: string;
  translationMonthDuration: string;
};

const createSeasonOption = (season: Season, market: Market, locale: Locale): SeasonOption => {
  const i18n = getI18n();

  const seasonTranslations: Record<Season, string> = {
    spring: i18n.t('search:season.spring'),
    summer: i18n.t('search:season.summer'),
    autumn: i18n.t('search:season.autumn'),
    winter: i18n.t('search:season.winter'),
  };

  const seasonInMonths = getSeasonInMonths(season, market);

  return {
    translationSeason: seasonTranslations[season],
    translationMonthDuration: `(${format(setMonth(new Date(), seasonInMonths[0]), 'LLL', {
      locale,
    })} - ${format(setMonth(new Date(), seasonInMonths[seasonInMonths.length - 1]), 'LLL', {
      locale,
    })})`,
  };
};

export const getPriceFilterSeasonTranslations = (market: Market, locale: Locale) =>
  priceFilterSeasonsArray.reduce(
    (acc, season) => ({
      ...acc,
      [season]: createSeasonOption(season, market, locale),
    }),
    {} as Record<Season, SeasonOption>,
  );

export const getPriceFilterSeasonMapping = (market: Market) =>
  priceFilterSeasonsArray.reduce(
    (acc, season) => ({
      ...acc,
      [season]: getSeasonInMonths(season, market),
    }),
    {} as Record<Season, Month[]>,
  );

export const priceFilterWeekDayMapping: Record<PriceFilterWeekDay, WeekDay[]> = {
  monWed: ['mon', 'tue', 'wed'],
  thu: ['thu'],
  fri: ['fri'],
  sat: ['sat'],
  sun: ['sun'],
};

/**
 * Maps the provided `weddingMonths` to their respective seasons based on the market.
 * If any months match a season from the `seasonMapping` for the given market, the season
 * will be marked as `true` in the returned object.
 *
 * @param weddingMonths - array of weddingMonths returned from searchResponse -> fields
 * @param market - market  defines the season-to-month mapping.
 *
 * @returns formatted object with seasons as keys and boolean values
 */
export const getWeddingSeasonFilterFromMonths = ({
  weddingMonths,
  market,
}: {
  weddingMonths?: Month[];
  market: Market;
}) => {
  if (!weddingMonths) return undefined;

  const seasonMapping = getPriceFilterSeasonMapping(market);

  const filteredSeasons = pickBy(
    (months) => weddingMonths.some((month) => months.includes(month)),
    seasonMapping,
  );

  return mapObjIndexed(() => true, filteredSeasons);
};

/**
 * Maps the provided `weddingWeekDays` to their respective week-day groupings.
 * If any weekdays match a predefined grouping from `priceFilterWeekDayMapping`,
 * the corresponding key will be marked as `true` in the returned object.
 *
 * @param weddingWeekDays - array of weddingWeekDays returned from searchResponse -> fields
 *
 * @returns formatted object with week-day groupings as keys and boolean values
 */
export const getWeddingDaysFilterFromResponse = (weddingWeekDays?: WeekDay[]) => {
  if (!weddingWeekDays) return;

  const filteredDays = pickBy(
    (days) => days.some((day: WeekDay) => weddingWeekDays.includes(day)),
    priceFilterWeekDayMapping,
  );

  return mapObjIndexed(() => true, filteredDays);
};
