import { AbstractCollection } from '../../abstract/Collection';
import { AbstractDocument, Identifiable, Timestampable } from '../../abstract/Document';
import { type Wedding, Weddings } from '../Weddings';
import type { IBookmark, IBookmarkType } from './Bookmarks.types';
import { where } from 'firebase/firestore';

@Identifiable
@Timestampable
export class Bookmark extends AbstractDocument<IBookmark> {
  readonly collections = {};
}

export class Bookmarks extends AbstractCollection<Bookmark, IBookmark> {
  static path = 'bookmarks';

  constructor(document: Wedding) {
    super(document.collection(Bookmarks.path), Bookmark);
  }

  get wedding() {
    return Weddings._.getById(this.reference.parent.id);
  }

  getAllByType(type: IBookmarkType) {
    return this.query([where('type', '==', type)]).get(true);
  }
}
