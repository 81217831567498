import React, {
  MouseEventHandler,
  ReactElement,
  ReactNode,
  cloneElement,
  useCallback,
} from 'react';
import mergeStyles from '@bridebook/ui/src/fela-utils/merge-styles';
import { IStylingProps, TColor } from '@bridebook/ui/src/themes/types';
import { TButtonV2Size } from '@bridebook/ui/src/types';
import { extractStylingProps } from '@bridebook/ui/src/utils/styling-props';
import Box from '../../fela/Box';
import { FelaCSS } from '../../fela/flowtypes';
import LoadingCircle from '../loading-circle/loading-circle';
import componentStyles from './button.styles';

export type TButtonVariant =
  | 'primary'
  | 'secondary'
  | 'secondary-blue'
  | 'link'
  // @deprecated
  | 'ghost';

interface IProps extends IStylingProps {
  /** What button variant to use */
  variant?: TButtonVariant;
  /** What button size to use */
  size?: TButtonV2Size;
  /** Button content */
  children?: ReactNode;
  /** Icon that will be displayed on the left */
  startIcon?: ReactNode;
  /** Icon that will be displayed on the right */
  endIcon?: ReactNode;
  /** Action performed on button click */
  onClick?: MouseEventHandler<ReactNode>;
  /** Alternative color scheme for main variants,
   * can be used on photos or dark backgrounds*/
  alternative?: boolean;
  /** Should button be in disabled state */
  disabled?: boolean;
  /** Custom style for the wrapper */
  styleWrapper?: FelaCSS;
  /** Custom style for the button */
  styleButton?: FelaCSS;
  /** Should button be focused on mount */
  autoFocus?: boolean;
  /** Is button in loading state */
  loading?: boolean;
  /** Element name attribute */
  name?: string;
  /** Optional value for `data-test` attribute, used in E2E tests */
  dataTest?: string;
  /** Optional id attribute */
  id?: string;
}

export const Button = ({
  variant = 'primary',
  size = 'normal',
  children,
  onClick,
  startIcon,
  endIcon,
  alternative,
  disabled,
  styleWrapper = {},
  styleButton = {},
  autoFocus = false,
  loading = false,
  name,
  dataTest,
  id,
  w = '100%',
  ...stylingProps
}: IProps) => {
  const isDisabled = disabled || loading;

  const styles = componentStyles({
    variant,
    size,
    hasChildren: !!children,
    alternative,
    isDisabled,
    isLoading: loading,
  });

  const getIconColor = (): TColor => {
    if (alternative) {
      switch (variant) {
        case 'primary':
          return 'space';
        case 'secondary':
        case 'secondary-blue':
          return isDisabled ? 'space40' : 'white';
        case 'ghost':
        default:
          return 'white';
      }
    } else {
      switch (variant) {
        case 'primary':
          return 'white';
        case 'secondary':
          return isDisabled ? 'space40' : 'space75';
        case 'secondary-blue':
          return isDisabled ? 'indigoCrush40' : 'indigoCrush';
        case 'link':
          return isDisabled ? 'indigoCrush40' : 'indigoCrush';
        default:
          return isDisabled ? 'space40' : 'space';
      }
    }
  };

  const onClickCb = useCallback<MouseEventHandler<ReactNode>>(
    (event) => {
      !isDisabled && onClick?.(event);
    },
    [isDisabled, onClick],
  );

  return (
    <Box
      id={id}
      name={name}
      as="button"
      autoFocus={autoFocus}
      onClick={onClickCb}
      disabled={isDisabled}
      dataTest={dataTest}
      style={mergeStyles([styles.wrapper, styleWrapper])}
      {...extractStylingProps(stylingProps)}
      w={w}>
      <Box style={mergeStyles([styles.button, styleButton])}>
        {startIcon && cloneElement(startIcon as ReactElement, { color: getIconColor() })}
        {children}
        {endIcon && cloneElement(endIcon as ReactElement, { color: getIconColor() })}
      </Box>
      {loading && (
        <Box style={styles.loader}>
          <LoadingCircle size={size === 'small' ? 20 : 30} color={getIconColor()} />
        </Box>
      )}
    </Box>
  );
};
