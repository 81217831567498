import { AndroidSettings, IOSSettings, NativeSettings } from 'capacitor-native-settings';
import { useTranslation } from 'next-i18next';
import React, { ReactNode, useCallback, useMemo } from 'react';
import { ButtonV2, Snackbar } from '@bridebook/ui';
import { TSnackbarMode } from '@bridebook/ui/src/components/bbcommon/snackbar/snackbar-content';
import { Link } from 'app-shared/components/link/link';
import { hideSnackbar } from 'lib/bbcommon/actions';
import { getSnackbarState } from 'lib/bbcommon/selectors';
import { requestPushNotificationPermissions } from 'lib/mobile-app/actions';
import { isCordovaPluginAvailable } from 'lib/mobile-app/utils/cordova-plugin-check';
import { useCheckPushPermissions } from 'lib/settings/hooks/use-check-push-permissions';
import { clickedAnalytics } from 'lib/ui/analytics';
import { UrlHelper } from 'lib/url-helper';
import { useDispatch, useSelector } from 'lib/utils';

export type TSnackbarAction = 'goToFavourites' | 'enableNotifications' | 'goToSavedInspiration';

const TOP_OFFSET = 56;

const SnackbarWrapper: React.FC = () => {
  const {
    show,
    mode,
    text,
    linesLimit,
    action,
    actionData,
    emoji,
    uuid,
    timeout,
    analyticsData,
    customOffset,
  } = useSelector(getSnackbarState);
  const dispatch = useDispatch();
  const { t } = useTranslation('common');
  const pushPermissions = useCheckPushPermissions();

  const handleEnableNotifications = useCallback(async () => {
    if (pushPermissions === 'prompt' || pushPermissions === 'prompt-with-rationale') {
      dispatch(requestPushNotificationPermissions());
    } else {
      if (isCordovaPluginAvailable('NativeSettings')) {
        NativeSettings.open({
          optionAndroid: AndroidSettings.AppNotification,
          optionIOS: IOSSettings.App,
        });
      }
    }
  }, [dispatch, pushPermissions]);

  const handleCloseSnackbar = useCallback(() => {
    dispatch(clickedAnalytics({ ...analyticsData }));
    dispatch(hideSnackbar());
  }, [analyticsData, dispatch]);

  const availableSnackbarActions: Record<TSnackbarAction, ReactNode> = useMemo(() => {
    const href = actionData?.supplierType
      ? UrlHelper.shortlist.tabBySupplierType(actionData.supplierType).as
      : actionData?.supplierShortlistUrl;

    return {
      goToFavourites:
        actionData && href ? (
          <Link href={href} onClick={handleCloseSnackbar}>
            {t('snackbar.actions.goToFavourites.label')}
          </Link>
        ) : null,
      enableNotifications: (
        <ButtonV2 variant={'link'} size={'small'} onClick={handleEnableNotifications}>
          {t('snackbar.actions.enableNotifications.turnOn')}
        </ButtonV2>
      ),
      goToSavedInspiration: (
        <Link href={UrlHelper.savedInspiration} onClick={handleCloseSnackbar}>
          {t('snackbar.view.label')}
        </Link>
      ),
    };
  }, [actionData, handleCloseSnackbar, t, handleEnableNotifications]);

  const handleHideSnackbar = useCallback(() => {
    if (show) {
      dispatch(hideSnackbar());
    }
  }, [dispatch, show]);

  return (
    <Snackbar
      show={show}
      uuid={uuid}
      timeout={timeout}
      onTimeout={handleHideSnackbar}
      mode={mode as TSnackbarMode}
      text={text}
      linesLimit={linesLimit}
      offset={customOffset || TOP_OFFSET}
      emoji={emoji}
      ctaContent={action ? availableSnackbarActions[action] : undefined}
    />
  );
};

export default SnackbarWrapper;
