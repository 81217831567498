import { createSlice } from '@reduxjs/toolkit';
import { ActionWithPayload, AppAction } from 'lib/types';
import {
  isHomePage,
  isImageInspirationPage,
  isSavedInspirationPage,
  isSupplierPage,
  removeMarketFromPath,
} from 'lib/utils/url';
import { ImageInspirationState } from './types';

// Adding this to redux for the MVP
// in a future iteration this could
// be added to url instead
const initialState: ImageInspirationState = {
  selectedImage: null,
  selectedPrompt: '',
};

const name = 'imageInspiration';

const imageInspirationSlice = createSlice({
  name,
  initialState,
  reducers: {
    setInspirationSelectedImage: (
      state,
      action: ActionWithPayload<ImageInspirationState['selectedImage']>,
    ) => {
      state.selectedImage = action.payload;
    },
    setInspirationSelectedPrompt: (
      state,
      action: ActionWithPayload<ImageInspirationState['selectedPrompt']>,
    ) => {
      state.selectedPrompt = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase('ROUTE_CHANGE_COMPLETE', (state, action) => {
      const { payload } = action as Extract<AppAction, { type: 'ROUTE_CHANGE_COMPLETE' }>;
      const { url, query, previousPath } = payload;

      const urlWithoutMarket = removeMarketFromPath(url, query.market);

      // Do not clear selectedImage and selectedPrompt if:
      // 1. Current page is supplier profile page
      // 2. Current page is home/inspiration/saved-inspiration and previous page was supplier page
      if (
        !(
          isSupplierPage(urlWithoutMarket) ||
          ((isHomePage(urlWithoutMarket) ||
            isImageInspirationPage(urlWithoutMarket) ||
            isSavedInspirationPage(urlWithoutMarket)) &&
            isSupplierPage(previousPath))
        ) &&
        (state.selectedImage || state.selectedPrompt)
      ) {
        state.selectedImage = null;
        state.selectedPrompt = '';
      }
    });
  },
});

export const { setInspirationSelectedImage, setInspirationSelectedPrompt } =
  imageInspirationSlice.actions;
export default imageInspirationSlice.reducer;
