import { useCallback, useEffect } from 'react';
import { FullStory, init as initFullStory } from '@fullstory/browser';
import { FULLSTORY_ORG_ID as orgId } from 'app-shared/lib/utils/constants';
import { getVariant_Fullstory, useABTest_Fullstory } from 'lib/ab-tests/tests/LIVE-20269_Fullstory';
import { getIsDesktop } from 'lib/app/selectors';
import { getCurrentUserId } from 'lib/users/selectors';
import { useSelector } from 'lib/utils';

/**
 * Hook for initializing FullStory tracking
 */
const useFullStory = () => {
  useABTest_Fullstory();
  const isFullStoryEnabled = useSelector(getVariant_Fullstory) === '1';
  const userId = useSelector(getCurrentUserId);
  const isDesktop = useSelector(getIsDesktop);

  const identifyFullStoryUser = useCallback(() => {
    if (!userId) return;
    FullStory('setIdentity', {
      uid: userId,
    });
  }, [userId]);

  useEffect(() => {
    // If FullStory controller is set to control, enable tracking for all users (% set in FullStory test);
    if (isFullStoryEnabled) {
      initFullStory({ orgId }, identifyFullStoryUser);
    }
    // This should split the FullStory quota between the two variants. The number is controller by LIVE-21023_FullStoryVariantController. If the user is in the control group they will be tracked regardless of map test
  }, [identifyFullStoryUser, isDesktop, isFullStoryEnabled]);
};

export default useFullStory;
