import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { auditTime, catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { appError } from 'lib/app/actions';
import { updateUserBudget } from 'lib/budget/actions';
import { Action, IEpicDeps } from 'lib/types';
import { upsertWeddingSupplier } from '../utils';

interface IBookingEpicCreatorArgs<A extends Action> {
  actionType: A['type'];
  analyticsType: string;
  booked: () => boolean;
  time?: number;
  auditTime?: number;
}

export const bookingEpicCreator =
  <A extends Action>({ actionType, analyticsType, booked, time = 0 }: IBookingEpicCreatorArgs<A>) =>
  (action$: Observable<A>, { state$ }: IEpicDeps) =>
    action$.pipe(
      ofType(actionType),
      auditTime(time),
      withLatestFrom(state$),
      mergeMap(
        ([
          {
            payload: { item, method },
          },
          state,
        ]) => {
          const { id: weddingId } = state.weddings.profile;
          const promise = upsertWeddingSupplier({
            supplier: item,
            weddingId,
            overwrite: { booked: booked() },
          });

          return from(promise).pipe(
            mergeMap(() =>
              of(updateUserBudget(item), {
                type: analyticsType,
                payload: { item, method },
              }),
            ),
          );
        },
      ),
      catchError((error) => of(appError({ error, feature: 'Shortlist' }))),
    );
