import invariant from 'invariant';
import Router, { useRouter } from 'next/router';
import { compose, concat, isEmpty, join, map, omit, toPairs, values } from 'ramda';
import type { ISupplier } from '@bridebook/models/source/models/Suppliers.types';
import { IVideo } from '@bridebook/models/source/models/Suppliers/Videos.types';
import { mapSlugToFriendlyUrlSlug, slugify } from '@bridebook/toolbox/src';
import gazetteer, { Market } from '@bridebook/toolbox/src/gazetteer';
import type { Slug, UrlFriendlySlug } from '@bridebook/toolbox/src/types';
import { useMarket } from 'app-shared/lib/i18n/hooks/use-market';
import { PublicUrls, UrlHelper } from 'lib/url-helper';
import { getLocalisedCanonical } from './i18n-routing/get-localised-canonical';

export const slugs: Partial<Record<Slug, string>> = {
  beauty: 'beauty-hair-makeup',
  cakes: 'cakes',
  catering: 'catering',
  decoration: 'decoration-hire',
  dress: 'dress-accessories',
  entertainment: 'entertainment',
  florist: 'florists',
  jewellery: 'rings-jewellery',
  marquee: 'marquee-hire',
  menswear: 'menswear',
  music: 'musicians-bands-djs',
  photo: 'photographers',
  planners: 'planners-toastmasters-celebrants',
  stationery: 'stationery',
  transport: 'transport',
  venue: 'venues',
  video: 'videographers',
};

export const nestedToParent = (parent: any) => {
  map((key) => {
    if (typeof parent[key] === 'object') {
      parent = { ...parent[key], ...parent };
      delete parent[key];
    }
    return key;
  }, Object.keys(parent));
  return parent;
};

export const getSupplierFriendlyUrlId = (supplier: ISupplier) =>
  `${supplier.seo?.urlSlug}-${supplier.publicId}`;

const removeThe = (urlArea: string) => urlArea.toLowerCase().replace(/^the-/g, '');

export const getLocationLandingUrl = (area: string, as = false) => {
  const sluggedArea = removeThe(slugify(area || 'United Kingdom')).toLowerCase();
  return as
    ? `/wedding-suppliers/venues/${sluggedArea}`
    : `/location-landing?slug=venues&area=${slugify(sluggedArea)}`;
};

export const toUrlQuery = (
  params: { [key: string]: Parameters<typeof encodeURIComponent>[0] },
  endPercent: boolean = false,
): string => {
  const query = Object.keys(params)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
    .join('&');
  return query ? `${endPercent ? '&' : '?'}${query}` : '';
};

export function isURL(value: string) {
  return /^(f|ht)tps?:[/][/](\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?([/]([-\w#!:.?+=&%@!/])?)?$/.test(value);
}

export function extractRootURL(value: string) {
  const match = value.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i);
  if (match != null && match.length > 2 && typeof match[2] === 'string' && match[2].length > 0) {
    return match[2].split('.')[0];
  }
  return false;
}

export function getVideoIframeUrl(video: IVideo, autoplay: boolean = true) {
  const id = video.sourceId;
  const type: IVideo['source'] = Array.isArray(video.source) ? video.source[0] : video.source;

  switch (type) {
    case 'youtube':
      return `https://youtube.com/embed/${id}${autoplay ? '?autoplay=1' : ''}`;
    case 'vimeo':
      return `https://player.vimeo.com/video/${id}${autoplay ? '?autoplay=1' : ''}`;
    case 'matterport':
    case 'googlemaps':
      return id;
    default:
      return '';
  }
}

export function prepareObjForElastic(obj: any) {
  invariant(typeof obj === 'object' && !Array.isArray(obj), 'Expected data to be object.');
  obj = nestedToParent(obj);
  return Object.keys(obj)
    .filter((value) => obj[value])
    .map((value) => ({ [value]: obj[value] }))
    .reduce((result, item) => {
      const [key] = Object.keys(item);
      result[key] = item[key];
      return result;
    }, {});
}

export const createQs = compose<
  Record<string, any>,
  readonly (readonly any[])[],
  string[],
  string,
  string
>(concat('?'), join('&'), map(join('=')), toPairs);

/**
 * Function `makeSearchQuery` generate query object based on fields/filters
 */
export const makeSearchQuery = (
  fields: Record<string, any>,
  filtersState: Record<string, string>,
  page: number,
): Record<string, any> => {
  const { swlat, swlon, nelat, nelon, searchOnMove, placeId } = fields;
  let query: any = searchOnMove ? { swlat, swlon, nelat, nelon, searchOnMove, placeId } : {};
  if (placeId) query.placeId = placeId;
  if (!isEmpty(page) && page !== 0 && page !== 1) query.page = page;
  const filters = prepareObjForElastic(filtersState);

  query = { ...query, ...filters };
  return query;
};

/**
 * Function `getCanonicalUrl` generate correct cannonical url for search page
 */
export const getCanonicalUrl = (
  type: Slug | UrlFriendlySlug,
  area: string,
  filtersState = {},
  market: Market,
  facets: string[],
) => {
  const filtersStateProps = makeSearchQuery({}, filtersState, 1);
  const filtersStateWithoutFacets = omit(facets, filtersStateProps);

  const params = !isEmpty(filtersStateWithoutFacets) ? createQs(filtersStateWithoutFacets) : '';

  const canonical = getLocalisedCanonical(market, '/search');
  const facetsStr = isEmpty(facets) ? '' : `/${facets.sort().join('/')}`;

  return `${canonical}/wedding-${mapSlugToFriendlyUrlSlug(type).newSlug}/${slugify(
    area,
  )}${facetsStr}${params}`;
};

/**
 * Function `makeSearchPathname` generate correct search path name for search
 *
 * @function makeSearchPathname
 * @param {Object} - params with area and type
 * @returns {String} - returns search path
 */
export const makeSearchPathname = (fields: { area: string; type: Slug | UrlFriendlySlug }) => {
  const { area, type } = fields;
  return `/search/wedding-${mapSlugToFriendlyUrlSlug(type).newSlug}/${area}`;
};

/**
 * Returns true if pathname matches supplier profile URL (excluding its subpages)
 */
export const isSupplierPage = (path: string): boolean =>
  Object.keys(slugs).some((key) => path.startsWith(`/wedding-${slugs[key as Slug]}`));

/**
 * Returns true if pathname matches image inspiration page
 */
export const isImageInspirationPage = (path: string): boolean =>
  path.startsWith(UrlHelper.inspiration);

/**
 * Returns true if pathname matches saved inspiration page
 */
export const isSavedInspirationPage = (path: string): boolean =>
  path.includes(UrlHelper.savedInspiration);

/**
 * Returns true if pathname matches supplier profile gallery URL
 */
export const isSupplierGalleryPage = (path: string) =>
  isSupplierPage(path) && path.includes('/gallery');

/**
 * Function isArticlePage returns boolean if path is matching article page path
 * @param path string as comes from nextjs router
 */
export const isArticlePage = (path: string): boolean => {
  const isArticlePageRegex = path.match('/article/(.*)');
  return Boolean(isArticlePageRegex);
};

/**
 * Function isHomePage returns boolean if path is matching home page path
 * @param path string as comes from nextjs router
 */
export const isHomePage = (path: string): boolean => path === '/home';

export enum ToolPaths {
  checklist = '/checklist',
  shortlist = '/shortlist',
  budget = '/budget',
  scrapbook = '/scrapbook',
  guestlist = '/guestlist',
  advice = '/advice',
  recommendations = '/recommendations',
  bookmarks = '/bookmarks',
  imageInspiration = '/inspiration',
}

/**
 * Function isToolPage returns boolean if path is matching one of the tool pages
 * @param path string as comes from nextjs router
 */
export const isToolPage = (path: string) => {
  let returnValue = false;
  values(ToolPaths).forEach((toolPath) => {
    if (path.indexOf(toolPath) === 0) {
      returnValue = true;
    }
  });
  return returnValue;
};

const { main, ...toolPaths } = PublicUrls.weddingPlanningTools;
export const LoggedOutToolPaths = toolPaths;

export const isLoggedOutToolPage = (path: string) => {
  let returnValue = false;
  values(LoggedOutToolPaths).forEach((toolPath) => {
    if (path.indexOf(toolPath) === 0) {
      returnValue = true;
    }
  });

  return returnValue;
};

/**
 * Resets the piling counter
 */
const shouldResetPiling = (pathname: string, previousPath: string) =>
  // When entering a supplier page from NOT a supplier page
  (isSupplierPage(pathname) && !isSupplierPage(previousPath)) ||
  // When entering an article page from NOT an article page
  (isArticlePage(pathname) && !isArticlePage(previousPath));

/**
 * Increments the piling counter
 */
const shouldIncrementPiling = (pathname: string, previousPath: string) =>
  // When entering a supplier page from another supplier page
  (isSupplierPage(pathname) && isSupplierPage(previousPath)) ||
  // When entering an article page from another article page
  (isArticlePage(pathname) && isArticlePage(previousPath));

/**
 * Returns true, if the piling counter should not be affected by given pathname or previousPath
 * (basically when a page should be invisible to the counter)
 */
const shouldKeepExistingPiling = (pathname: string, previousPath: string) =>
  isSupplierGalleryPage(pathname) || isSupplierGalleryPage(previousPath);

export const getNewPilingCounter = (
  pathname: string,
  previousPath: string,
  currentPilingCounter: number,
) => {
  if (shouldKeepExistingPiling(pathname, previousPath)) {
    return currentPilingCounter;
  }
  if (shouldResetPiling(pathname, previousPath)) {
    return 1;
  }
  if (shouldIncrementPiling(pathname, previousPath)) {
    return currentPilingCounter + 1;
  }
  return 0;
};

export const getAsPathNormalized = () => {
  const { asPath } = Router;

  return asPath;
};

export const getAsPathWithoutMarket = () => {
  const { asPath } = Router;
  try {
    const marketFromQuery = Router.query.market;
    const market = gazetteer.getMarketByURL(
      Array.isArray(marketFromQuery) ? marketFromQuery[0] : marketFromQuery,
    );
    return removeMarketFromPath(asPath, market.prefix);
  } catch (e) {
    return asPath;
  }
};

/**
 * Remove market prefix from the given path
 */
export const removeMarketFromPath = (path: string, marketPrefix = '') => {
  if (!marketPrefix) return path;
  return path.replace(new RegExp(`[/]${marketPrefix}(?:[/]|$)`), '/');
};

/**
 * Hook returns the current pathname without the market
 */
export const usePathWithoutMarket = () => {
  const { asPath } = useRouter();
  const market = useMarket();
  return removeMarketFromPath(asPath, market.prefix);
};
