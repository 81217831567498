import { FelaCSS, colors } from '@bridebook/ui';

interface IProps {
  isMapView: boolean;
}

interface IStyles {
  wrapper: FelaCSS;
  inputWrap: FelaCSS;
  filterWrap: FelaCSS;
  sortWrap: FelaCSS;
  buttonWrap: FelaCSS;
  verticalDivider: FelaCSS;
}

const styles = ({ isMapView }: IProps): IStyles => ({
  wrapper: {
    flexDirection: 'column',
    flexShrink: 0,
    paddingVertical: undefined,
    backgroundColor: isMapView ? 'rgba(0,0,0,0)' : colors.white,
    gap: 12,
    flexWrap: 'nowrap',
    borderBottom: `1px solid ${colors.space15}`,
  },

  inputWrap: {
    flexGrow: 1,
    paddingLeft: 4,
    marginRight: '10px',
  },

  filterWrap: {
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden',
  },

  sortWrap: {
    flexGrow: 1,
    marginRight: '10px',
  },

  buttonWrap: {
    flexShrink: 0,
    flexGrow: 0,
  },
  verticalDivider: {
    borderRight: `1px ${colors.space15} solid`,
    marginHorizontal: 2,
    height: 26,
    marginTop: 1,
  },
});

export default styles;
