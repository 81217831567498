/**
 * Returns given string with the first letter capitalized
 */
export const capitalizeFirstLetter = (s: string = '') => s.charAt(0).toUpperCase() + s.slice(1);

/**
 * Returns given string with the first letter capitalized and the rest lowercase
 * */
export const capitalizeFirstLetterAndLowercaseRest = (string: string): string =>
  string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();

/**
 * Returns true if a given URL contains http(s)
 * @param url
 */
export const isExternalURL = (url: string) => {
  const httpMatch = /\b(http|https)/;
  return httpMatch.test(url);
};
