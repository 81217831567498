import type { PartialRecursive } from '../abstract/_';
import { AbstractCollection } from '../abstract/Collection';
import { AbstractDocument, Identifiable, Timestampable, Untrackable } from '../abstract/Document';
import type { IWedding } from './Weddings.types';
import { Badges } from './Weddings/Badges';
import { Bookmarks } from './Weddings/Bookmarks';
import { Costs } from './Weddings/Costs';
import { FeedbackCollection } from './Weddings/Feedback';
import { Files } from './Weddings/Files';
import { Guests } from './Weddings/Guests';
import { Keywords } from './Weddings/Keywords';
import { Notes } from './Weddings/Notes';
import { Photos } from './Weddings/Photos';
import { Preferences } from './Weddings/Preferences';
import { Subscriptions } from './Weddings/Subscriptions';
import type { IWeddingSubscription } from './Weddings/Subscriptions.types';
import { Suppliers } from './Weddings/Suppliers';
import { Tasks } from './Weddings/Tasks';
import { Users } from './Weddings/Users';
import type { Market } from '@bridebook/toolbox/src/gazetteer';
import { deleteField } from 'firebase/firestore';
import { mergeDeepRight, pathOr } from 'ramda';

@Identifiable
@Timestampable
@Untrackable
export class Wedding extends AbstractDocument<IWedding> {
  readonly collections = {
    Badges: new Badges(this),
    Bookmarks: new Bookmarks(this),
    Costs: new Costs(this),
    Files: new Files(this),
    Feedback: new FeedbackCollection(this),
    Guests: new Guests(this),
    Keywords: new Keywords(this),
    Notes: new Notes(this),
    Photos: new Photos(this),
    Preferences: new Preferences(this),
    Subscriptions: new Subscriptions(this),
    Suppliers: new Suppliers(this),
    Tasks: new Tasks(this),
    Users: new Users(this),
  };

  /**
   * Returns the country code of this wedding, falling back to `GB`.
   */
  get country() {
    return this.getCountry();
  }

  /**
   * Returns the country code of this wedding, falling back to `GB`.
   */
  async getCountry() {
    return pathOr('GB', ['l10n', 'country'], await this.get()) as string;
  }

  /**
   * Get Premium Document from subscriptions subcollection
   */
  getPremium() {
    return this.collections.Subscriptions.getPremium();
  }

  /**
   * @deprecated Use `Suppliers.getShortlisted('venue')` instead.
   */
  getShortlistedVenues() {
    return this.collections.Suppliers.getShortlisted('venue');
  }

  /**
   * Set Premium Document from subscriptions subcollection
   */
  setPremium(premium: PartialRecursive<IWeddingSubscription>) {
    return this.collections.Subscriptions.setPremium(premium);
  }

  /**
   * Updates the wedding country: updates wedding.l10n.country field and sets wedding.location to the new country.
   * @param {Market} market - the new wedding market
   */
  updateCountry(market: Market) {
    return this.set(
      {
        l10n: { country: market.country },
        location: {
          adminArea: deleteField(),
          city: deleteField(),
          country: market.country,
          name: market.getCountryName(),
          postalCode: deleteField(),
          provider: deleteField(),
          street: deleteField(),
        },
      },
      true,
    );
  }
}

export class Weddings extends AbstractCollection<Wedding, IWedding> {
  static definitions = {
    _: {} as IWedding,
  };

  static path = 'weddings';

  constructor() {
    super(Weddings.path, Wedding);
  }

  static new<M extends typeof Weddings.definitions, K extends keyof M>(key: K, value?: PartialRecursive<M[K]>) {
    let result: PartialRecursive<IWedding> = {
      guests: {
        categories: {
          data: {
            '00000000-0000-4000-8000-000000000001': 'me',
            '00000000-0000-4000-8000-000000000002': 'partner',
          },
          sort: ['00000000-0000-4000-8000-000000000001', '00000000-0000-4000-8000-000000000002'],
        },
        count: 0,
      },
      location: null,
      notes: {
        count: 0,
      },
      partners: ['', ''],
      planningStage: {
        hasBudget: false,
        hasGuests: false,
        hasNotStarted: false,
        hasVenue: false,
        hasVenueStyle: false,
        hasWeddingDate: false,
      },
      roles: ['', ''],
      suppliers: {
        count: 0,
      },
      tasks: {
        done: 0,
        total: 0,
      },
    };

    if (key !== '_' && key in Weddings.definitions) {
      result = (result[key as keyof Omit<typeof Weddings.definitions, '_'>] as PartialRecursive<M[K]>) || {};
    }

    if (value != null) {
      result = mergeDeepRight(result, value) as PartialRecursive<M[K]>;
    }

    return result as M[K];
  }

  static get _() {
    return new this();
  }
}
