import { WebAnalyticsContext } from 'lib/bbcommon/utils/bridebook-analytics';
import { Action } from 'lib/types';
import { ImageInspirationAnalyticsAction } from './action-types';
import {
  IImageInspirationLoadedResults,
  IInteractedWithImageInspirationBoard,
  IViewedImageInspiration,
} from './actions';

export default function imageInspirationAnalytics(
  { type, payload }: Action,
  bridebookAnalytics: WebAnalyticsContext,
) {
  const { track } = bridebookAnalytics.getMethods('ImageInspiration');

  switch (type) {
    case ImageInspirationAnalyticsAction.LoadedResults: {
      track({
        ...(payload as IImageInspirationLoadedResults),
        event: type,
      });
      break;
    }

    case ImageInspirationAnalyticsAction.ViewedImage: {
      const { imageData, ...rest } = payload as IViewedImageInspiration;
      track({
        ...rest,
        supplierCategory: imageData.type,
        supplierName: imageData.name,
        countryCode: imageData.countryCode,
        countryName: imageData.adminAreaLevel1,
        supplierId: imageData.supplierId,
        supplierTier: imageData.tier,
        event: type,
      });
      break;
    }

    case ImageInspirationAnalyticsAction.InteractedWithInspirationBoard: {
      track({
        ...(payload as IInteractedWithImageInspirationBoard),
        event: type,
      });
      break;
    }

    default:
      break;
  }
}
