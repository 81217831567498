import { useCallback, useMemo } from 'react';
import {
  GUEST_CAPACITY_MAX,
  GUEST_CAPACITY_MIN,
} from '@bridebook/toolbox/src/search-suppliers/elastic-queries/filters/constants';
import { useUpdateFilters } from 'components/search/hooks';
import { IQuickDropdownRangeHookData } from 'lib/types';
import { useSelector } from 'lib/utils';
import { IGuestOption, getGuestFilterOptions } from '../get-guest-filter-options';
import { getFilters } from '../selectors';
import getCapacityDiningRange from '../utils/get-capacity-dining-range';

type IGuestFilter = IQuickDropdownRangeHookData<IGuestOption, number | undefined>;

const useGuestsFilter = (location: string): IGuestFilter => {
  const filters = useSelector(getFilters);

  const optionItems = getGuestFilterOptions();
  const currentRange = getCapacityDiningRange(filters);
  const isFilterActive = currentRange ? currentRange[0] > 0 : false;
  const updateFilters = useUpdateFilters(location);
  const checkedOptions = useMemo<IGuestOption[]>(
    () =>
      isFilterActive
        ? optionItems.filter((optionItem) => optionItem.minValue <= currentRange[0]).slice(-1)
        : [],
    [currentRange, isFilterActive, optionItems],
  );

  const onFilterApply: IGuestFilter['onFilterApply'] = useCallback(
    (minValue = GUEST_CAPACITY_MIN, maxValue = GUEST_CAPACITY_MAX, skipAnalytics = false) => {
      updateFilters({
        name: 'misc',
        value: {
          capacityDining: `${minValue}-${maxValue}`,
        },
        skipAnalytics,
      });
    },
    [updateFilters],
  );

  const onFilterClear: IGuestFilter['onFilterClear'] = useCallback(() => {
    updateFilters({
      name: 'misc',
      value: { capacityDining: undefined },
    });
  }, [updateFilters]);

  return {
    optionItems,
    isFilterActive,
    checkedOptions,
    onFilterApply,
    onFilterClear,
  };
};

export default useGuestsFilter;
