import { IUISupplier } from '@bridebook/toolbox/src/types';
import { ITrackEventAction } from 'lib/track-utils/actions';
import { TrackingEvent } from 'lib/track-utils/tracking-event';

const isSendEnquiryAction = (
  action: ITrackEventAction<any>,
): action is ITrackEventAction<IUISupplier> =>
  action.event === TrackingEvent.SendEnquiry &&
  action.payload !== undefined &&
  'type' in action.payload;

export const isRelevantAction = (action: ITrackEventAction<any>): boolean =>
  !(isSendEnquiryAction(action) && action.payload?.type !== 'venue');
