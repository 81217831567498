import { getAuth } from 'firebase/auth';
import { SentryMinimal } from '@bridebook/toolbox/src/sentry';
import { env } from 'lib/env';

export type State<T extends string> = {
  status: T;
};

export function assertState<T extends string>(
  state: { status: string },
  expectedStatus: T,
  feature?: string,
): asserts state is State<T> {
  if (expectedStatus !== state.status) {
    try {
      throw new Error(
        `Invalid state: ${state.status} expected: ${expectedStatus}. Feature: ${feature}`,
      );
    } catch (e) {
      const isUserLoggedIn = getAuth().currentUser;

      // assertState is meant to be used only for logged in pages. Send only sentry error for logged in users
      if (isUserLoggedIn) {
        if (env.LIVE) {
          SentryMinimal().captureException(e);
        } else {
          throw e;
        }
      }
    }
  }
}
