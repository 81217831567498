import { useTranslation } from 'next-i18next';
import React, { useCallback } from 'react';
import { useFela } from 'react-fela';
import AppBarBackButton from 'components/app-bar/app-bar-mobile/partials/app-bar-back-button';
import CTASearchButton from 'components/bbcommon/cta-search-button/cta-search-button';
import { AllFiltersButton } from 'components/search/filters/quick-filters/all-filters-button';
import { setSearchSource, toggleSearchModal } from 'lib/search/actions';
import { useSearchParams } from 'lib/search/hooks/query/use-search';
import { getSearchRequest } from 'lib/search/selectors';
import { toTitleCase, useDispatch, useSelector } from 'lib/utils';
import styles from './mobile-search-top-bar.style';

interface MobileSearchTopBarProps {}

export const MobileSearchTopBar: React.FC<MobileSearchTopBarProps> = () => {
  const { t } = useTranslation('search');
  const { css } = useFela();
  const searchParams = useSearchParams();

  const dispatch = useDispatch();
  const { formattedArea: area } = useSelector(getSearchRequest);

  const isSearchOnMove = !!searchParams?.searchOnMove;
  const searchOnMoveTitle = t('searchHeading.title.mapArea');
  const searchLocation = isSearchOnMove ? searchOnMoveTitle : area && toTitleCase(area);

  const handleOpenModalAtLocationTab = useCallback(() => {
    dispatch(setSearchSource('searchPageTopRightSearchBar'));
    dispatch(toggleSearchModal('location'));
  }, [dispatch]);

  return (
    <div className={css(styles.root)}>
      <div className={css(styles.backBtn)}>
        <AppBarBackButton />
      </div>
      <CTASearchButton text={searchLocation} onClick={handleOpenModalAtLocationTab} size="small" />
      <AllFiltersButton />
    </div>
  );
};
