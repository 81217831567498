import { IUISupplier } from '@bridebook/toolbox/src/types';
import { getSupplierL10nAnalytics } from 'lib/analytics-utils/get-supplier-l10n';
import {
  LIVE_21330_getEnquiryHasIntentFastResponseABTest,
  getEnquiryFormIntents,
} from 'lib/enquiries/selectors';
import { IApplicationState } from 'lib/types';
import { IServerSideTrack } from 'lib/utils/server-side-track';
import enquirySupplierTrack from 'lib/utils/server-side-track/enquiry-supplier-track';
import { weddingDetailsPropertiesGeneric } from 'lib/weddings/analytics';
import { CriticalWebEvents } from '../../analyticsTypes';
import { enquiryPropertiesGeneric } from '../analytics';
import getEnquiriesAnalyticsProps from './get-analytics-props';

interface IEnquiriesServerSideTrack {
  event: string;
  id?: string;
  state: IApplicationState;
  contactedSupplier: IUISupplier;
  infoProps?: Record<string, any>;
  inboxMessageSent?: boolean;
  contactType?: string;
  contactMethod?: string;
  linkedSupplierName?: string;
  linkedSupplierId?: string;
}

const enquiriesServerSideTrack = ({
  event,
  id,
  state,
  contactedSupplier,
  infoProps = {},
  inboxMessageSent,
  contactType,
  contactMethod,
  linkedSupplierName,
  linkedSupplierId,
}: IEnquiriesServerSideTrack): Promise<any> => {
  const {
    users: { user },
    weddings: {
      profile: { id: weddingId },
    },
    enquiries: { enquirySupplier, multipleEnquiry, infoProps: stateInfoProps },
  } = state;

  const supplier = contactedSupplier || enquirySupplier;

  const {
    contactLocation,
    contactLocationType,
    contactActionLabel,
    contactGalleryCTA,
    cta,
    contactSection,
  } = getEnquiriesAnalyticsProps({
    getState: () => state,
    infoProps: {
      ...stateInfoProps,
      ...Object.fromEntries(Object.entries(infoProps).filter(([, value]) => !!value)), // Avoid falsey values
    },
  });

  const analyticsProps: IServerSideTrack = {
    state,
    event,
    category: 'Supplier Engagement',
    specificEventProps: {
      enquiryId: id || undefined,
      weddingId,
      ...enquiryPropertiesGeneric({
        getState: () => state,
        supplier,
        contactLocation,
        contactLocationType,
        contactActionLabel,
        contactGalleryCTA,
        contactSection,
        cta,
      }),
      ...weddingDetailsPropertiesGeneric(state),
      ...(contactType ? { contactType } : {}),
      ...(contactMethod ? { contactMethod } : {}),
      ...(linkedSupplierName ? { linkedSupplierName } : {}),
      ...(linkedSupplierId ? { linkedSupplierId } : {}),
      inboxMessageSent,
    },
    contactedSupplier: supplier,
  };

  if (!analyticsProps.specificEventProps) {
    analyticsProps.specificEventProps = {};
  }

  const { supplierCountryCode, supplierCountryName, adminArea0, adminArea1 } =
    getSupplierL10nAnalytics(supplier);

  switch (event) {
    case 'Failed to send enquiry':
      analyticsProps.specificEventProps.failedToSendEnquiryReason = infoProps.errorMessage;
      break;
    case 'Failed to confirm details':
      analyticsProps.specificEventProps.failedToConfirmDetailsReason = infoProps.errorMessage || '';
      break;
    case 'Confirmed enquiry details':
      analyticsProps.specificEventProps.enquiryDetailsConfirmLocation =
        infoProps.enquiryDetailsConfirmLocation || '';
      break;
    case 'Closed enquiry form':
      analyticsProps.specificEventProps.closedEnquiryFormMethod = infoProps.closeMethod || '';

      if (LIVE_21330_getEnquiryHasIntentFastResponseABTest(state)) {
        analyticsProps.specificEventProps.contactIntent.highIntent =
          getEnquiryFormIntents(state).requestFastResponse;
      }
      break;
    case CriticalWebEvents.EMAILED_SUPPLIER:
      if (multipleEnquiry) {
        analyticsProps.specificEventProps.contactType = 'multipleEnquiry';
      }

      if (!analyticsProps.specificEventProps.contactType) {
        analyticsProps.specificEventProps.contactType = 'singleEnquiry';
      }

      analyticsProps.specificEventProps.supplierCountryCode = supplierCountryCode;
      analyticsProps.specificEventProps.supplierCountryName = supplierCountryName;
      analyticsProps.specificEventProps.adminArea0 = adminArea0;
      analyticsProps.specificEventProps.adminArea1 = adminArea1;

      if (LIVE_21330_getEnquiryHasIntentFastResponseABTest(state)) {
        analyticsProps.specificEventProps.contactIntent.highIntent =
          getEnquiryFormIntents(state).requestFastResponse;
      }
      break;
    default:
      break;
  }

  const userEmail = user?.contacts?.email;

  if (userEmail) {
    analyticsProps.identifyProps = { userEmail };
  }

  return enquirySupplierTrack(analyticsProps);
};

export default enquiriesServerSideTrack;
