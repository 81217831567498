import { CohortNames, IAbTestingState } from 'src/ab-testing/lib/types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const initialState: IAbTestingState = {
  abTests: {},
  abTestsLoaded: false,
};

export const abTestingSlice = createSlice({
  name: 'abTesting',
  initialState,
  reducers: {
    setAbTestVariant(state, action: PayloadAction<{ id: string; variant: CohortNames }>) {
      const { id, variant } = action.payload;
      state.abTests[id] = variant;
    },
    setAbTestsLoaded(state, action: PayloadAction<boolean>) {
      state.abTestsLoaded = action.payload;
    },
  },
});

export const { setAbTestVariant, setAbTestsLoaded } = abTestingSlice.actions;
export default abTestingSlice.reducer;
