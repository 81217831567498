import { countriesWithSearchPresets } from '@bridebook/toolbox/src/i18n/features';
import { useMarket } from 'app-shared/lib/i18n/hooks/use-market';
import { useSearchParams } from 'lib/search/hooks/query/use-search';
import { getSearchRequestFormattedArea } from 'lib/search/selectors';
import { getIsCountrySearch } from 'lib/search/utils/get-is-country-search';
import { useSelector } from 'lib/utils';

export const useShowPresets = () => {
  const market = useMarket();
  const searchParams = useSearchParams();
  const formattedArea = useSelector(getSearchRequestFormattedArea) || '';

  const isCountrySearch = getIsCountrySearch(formattedArea);

  const searchCountryCode = searchParams?.market.country;
  const weddingCountryCode = market.country;

  return (
    searchParams?.slug === 'venues' &&
    isCountrySearch &&
    searchCountryCode &&
    countriesWithSearchPresets.includes(searchCountryCode) &&
    weddingCountryCode === searchCountryCode
  );
};
