type AwaitedRecursive<T> = T extends Promise<infer U>
  ? AwaitedRecursive<U>
  : T extends Record<PropertyKey, unknown>
  ? { -readonly [K in keyof T]: AwaitedRecursive<T[K]> }
  : T;

/**
 * Returns the number of unsettled (not yet resolved nor rejected) promises in the provided argument.
 */
export function countPromises<T>(data: T): number {
  const process = (input: unknown): number => {
    if (Array.isArray(input)) {
      return input.reduce<number>((count, item) => count + process(item), 0);
    }

    if (isObject(input)) {
      if (isPromise(input)) {
        return 1;
      }

      return Object.values(input).reduce<number>((count, value) => count + process(value), 0);
    }

    return 0;
  };

  return process(data);
}

/**
 * Returns whether the provided argument is an object.
 */
function isObject(data: unknown): data is Record<PropertyKey, unknown> {
  return data !== null && typeof data === 'object';
}

/**
 * Returns whether the provided argument is a promise.
 */
export function isPromise<T>(data: unknown): data is Promise<T> {
  return data instanceof Promise;
}

/**
 * Same as `Promise.all()` but besides arrays it also accepts objects.
 */
export function promiseAll<T extends readonly unknown[] | [] | Record<PropertyKey, unknown>>(
  data: T,
): Promise<{ -readonly [K in keyof T]: Awaited<T[K]> }> {
  if (Array.isArray(data)) {
    return Promise.all(data);
  }

  return Promise.all(Object.entries(data).map(async ([k, v]) => [k, await v])).then(
    Object.fromEntries,
  );
}

/**
 * Recursive version of `promiseAll()`, besides arrays it also accepts objects.
 */
export async function promiseAllDeep<
  T extends readonly unknown[] | [] | Record<PropertyKey, unknown>,
>(data: T): Promise<AwaitedRecursive<T>> {
  const process = async (input: unknown): Promise<any> => {
    if (Array.isArray(input)) {
      return Promise.all(input.map(process));
    }

    if (isObject(input)) {
      if (isPromise(input)) {
        return await input;
      }

      return Promise.all(Object.entries(input).map(async ([k, v]) => [k, await process(v)])).then(
        Object.fromEntries,
      );
    }

    return input;
  };

  return await process(data);
}

/**
 * Same as `Promise.allSettled()` but besides arrays it also accepts objects.
 */
export function promiseAllSettled<T extends readonly unknown[] | [] | Record<PropertyKey, unknown>>(
  data: T,
): Promise<{ -readonly [K in keyof T]: PromiseSettledResult<Awaited<T[K]>> }> {
  if (Array.isArray(data)) {
    return Promise.allSettled(data);
  }

  return Promise.all(
    Object.entries(data).map(async ([k, v]) => [
      k,
      await Promise.allSettled([v]).then(([result]) => result),
    ]),
  ).then(Object.fromEntries);
}
