import type {
  ISupplier,
  ISupplier_PricingIndicator,
} from '@bridebook/models/source/models/Suppliers.types';
import { IPhoto } from '@bridebook/models/source/models/Suppliers/Photos.types';
import { extractSupplier } from '@bridebook/toolbox/src';
import gazetteer, { CountryCodes, Market } from '@bridebook/toolbox/src/gazetteer';
import { wm64 } from '@bridebook/toolbox/src/getWatermarkParams';
import type { IUISupplier, Slug, UrlFriendlySlug } from '@bridebook/toolbox/src/types';
import { getI18n } from 'lib/i18n/getI18n';
import { getExcerpt, imgixBaseURL } from 'lib/utils';
import { prettySupplierCategory } from '../utils';
import { pricingCategoryByIndicator } from './constants';
import convertSupplierPhoto from './utils/convert-supplier-photo';

const valuesObj: Record<Slug, string> = {
  beauty: 'beautician',
  cakes: 'cake supplier',
  catering: 'caterer',
  decoration: 'decorator',
  dress: 'dressmaker',
  entertainment: 'entertainer',
  florist: 'florist',
  group: 'group',
  jewellery: 'jeweller',
  marquee: 'marquee supplier',
  menswear: 'menswear supplier',
  music: 'musician',
  photo: 'photographer',
  planners: 'planner',
  stationery: 'stationery',
  transport: 'transport',
  venue: 'venue',
  video: 'videographer',
};
/**
 * get insertA in plural
 * @method getInsertA
 * @param {string} slug - supplier slug to get insertA for
 */
export const getInsertA = (slug: Slug): string => valuesObj[slug] ?? 'supplier';

const hasAddressPart = (value?: unknown): boolean => typeof value === 'string' && value.length > 2;

const visibleParts = (supplier: ISupplier) => {
  const {
    hideFirstAddressLine,
    address,
    town,
    postcode,
    county,
    country: adminArea1,
    countryCode,
  } = extractSupplier(supplier);

  return {
    ...(hideFirstAddressLine
      ? {
          address: null,
          postcode: null,
        }
      : {
          address,
          postcode,
        }),
    town,
    county,
    adminArea1,
    countryCode,
  };
};

const formatAddressPart = (value: string): string => (hasAddressPart(value) ? value : '');

const hasEnoughAddressParts = (supplier: ISupplier): number => {
  const { address, town, county, postcode } = visibleParts(supplier);
  const parts = [address, town, county, postcode];

  return parts.reduce((result, part) => result + (hasAddressPart(part) ? 1 : 0), 0);
};

const getAddressParts = (supplier: ISupplier): string[] => {
  const { address, town, county, postcode, adminArea1, countryCode } = visibleParts(supplier);

  const basicFormat = [address, [postcode, town].filter(Boolean).join(' '), adminArea1].filter(
    Boolean,
  ) as string[];

  if (!countryCode) return basicFormat;

  const market = gazetteer.getMarketByCountry(countryCode);

  // Address without county or country
  const basicFormatWithCountryCode = [
    address,
    [postcode, town].filter(Boolean).join(' '),
    adminArea1,
    market.getCountryName(),
  ].filter(Boolean) as string[];

  switch (countryCode) {
    case CountryCodes.DE:
      /**
       * German address format is different to the UK (and will likely
       * be different in other countries as well).
       * https://www.angloinfo.com/how-to/germany/housing/postal-system
       **/
      return basicFormatWithCountryCode;
    default:
      return [address, town, county, postcode, adminArea1, market.getCountryName()].filter(
        Boolean,
      ) as string[];
  }
};

export const formatAddress = (supplier: ISupplier): string | null => {
  const parts = getAddressParts(supplier);

  if (hasEnoughAddressParts(supplier) > 1) {
    return parts.map(formatAddressPart).join(', ');
  }

  return null;
};

export const formatShortAddress = (supplier: ISupplier): string | null => {
  const town = supplier?.address?.city;
  const county = supplier?.address?.adminArea?.[0];
  const country = gazetteer.getMarketByCountry(supplier?.l10n.country);
  const isCoreMarket = country.flags.monetized;

  // For non-core markets, show also the country name
  const parts = isCoreMarket ? [town, county] : [town, county, country.getCountryName()];

  if (hasEnoughAddressParts(supplier) > 1) {
    return parts
      .map(formatAddressPart)
      .filter((part) => part !== '')
      .join(', ');
  }

  return null;
};

export const getPhotoUrl = (photo: Pick<IPhoto, 'path'>) => `${imgixBaseURL}/${photo?.path ?? ''}`;

/**
 * Returns the first photo that has a defined path.
 */
export const getPhotoWithPath = (photos: IPhoto[] | undefined) =>
  photos?.find((photo) => photo.path != null) ?? undefined;

/**
 * Returns all photos that have a defined path.
 */
export const getPhotosWithPath = (photos: IPhoto[] | undefined) =>
  photos?.filter((photo) => photo.path != null) ?? [];

export const getVenueTitle = ({
  name,
  category,
  market,
  customTitle,
  isLoggedIn,
}: {
  name: string;
  category: Slug | UrlFriendlySlug | '';
  market: Market;
  customTitle?: string;
  isLoggedIn: boolean;
}): string => {
  const appendCountryName = !market.flags.monetized && !isLoggedIn;
  if (category) {
    let translatedCategory = prettySupplierCategory({
      addWedding: true,
      amount: 1,
      market,
      slug: category,
      isLoggedIn,
    });

    // SEO exceptions
    // TODO: [i18n][bb-global] Find a better solution other than comparind single countries
    if (category === 'venue' && market.country === CountryCodes.FR) {
      const i18n = getI18n();
      translatedCategory = i18n.t('category.venues.extra');
      translatedCategory = translatedCategory.charAt(0).toUpperCase() + translatedCategory.slice(1);

      return customTitle || [name, translatedCategory].filter((v) => !!v).join(' | ');
    }

    const titleAndCategory = customTitle || [name, translatedCategory].filter((v) => !!v).join(' ');
    return appendCountryName
      ? `${titleAndCategory} | ${market.getCountryName()}`
      : titleAndCategory;
  }

  const title = customTitle || name || '';
  return appendCountryName ? `${title} | ${market.getCountryName()}` : title;
};

export const getMetaDesc = ({
  name,
  type,
  description,
  detailedDescription,
}: IUISupplier): string => {
  const maxWords: number = 25;
  let metaDesc: string;
  if (description) {
    metaDesc = getExcerpt(description, maxWords);
  } else if (detailedDescription) {
    metaDesc = getExcerpt(detailedDescription, maxWords);
  } else {
    const supplierName: string = name.trim();
    const insertA: string = getInsertA(type);
    metaDesc = `Bridebook wedding supplier page for ${supplierName}. Contains all details and information for this wedding ${insertA} with photos and videos.`;
  }
  return metaDesc;
};
const cloud = 'https://images.bridebook.com/';
const wmIndex = `?auto=compress%2Cformat&dpr=1&mark64=${wm64}&markw=50&markalpha=30&markalign=left&crop=entropy&fit=crop&w=770&h=400&marky=335&markx=10`;
const getMeta = (p: { public_id?: string; ext?: string }, property = 'og:image') => {
  const ext = p?.ext ? p.ext : 'jpg';
  return p?.public_id
    ? {
        property,
        content: `${cloud}${p.public_id}.${ext}${wmIndex}`,
      }
    : {};
};

export const getSupplierMeta = ({
  town,
  county,
  lat,
  lng,
  name,
  photos,
  address,
  postcode,
  country,
  title,
}: {
  town?: string;
  county?: string;
  lat?: number;
  lng?: number;
  name: string;
  photos?: IPhoto[];
  address: string;
  postcode?: string;
  // @TODO: [i18n] Should this be typed as CountryCodes?
  country?: string;
  title: string;
}) => {
  const metas: Record<string, any>[] = [
    { property: 'og:type', content: 'place' },
    { property: 'og:title', content: title },
    { property: 'og:site_name', content: 'Bridebook' },
    { property: 'twitter:card', content: 'summary_large_image' },
    { property: 'twitter:site', content: '@bridebook' },
    { property: 'twitter:title', content: name },
    { property: 'og:image:width', content: '530' },
    { property: 'og:image:height', content: '280' },
    { property: 'og:type', content: 'place' },
    { property: 'place:location:latitude', content: lat },
    { property: 'place:location:longitude', content: lng },
    { property: 'place:locality', content: town },
    { property: 'place:region', content: county },
    { property: 'place:street_address', content: address },
    { property: 'place:postal_code', content: postcode },
    { property: 'place:country', content: country },
  ];
  if (!photos) return metas;

  const [p0, p1, p2, p3] = photos.map(convertSupplierPhoto);

  const imageTW0 = getMeta(p0, 'twitter:image0');
  const imageOG0 = getMeta(p0);
  const imageOG1 = getMeta(p1);
  const imageOG2 = getMeta(p2);
  const imageOG3 = getMeta(p3);

  if (imageTW0) metas.push(imageTW0);
  if (imageOG0) metas.push(imageOG0);
  if (imageOG1) metas.push(imageOG1);
  if (imageOG2) metas.push(imageOG2);
  if (imageOG3) metas.push(imageOG3);

  return metas;
};

export const getPricingCategoryByIndicator = (indicator: ISupplier_PricingIndicator | undefined) =>
  indicator ? pricingCategoryByIndicator[indicator] : undefined;
